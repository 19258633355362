import React from "react";
import { connect } from "react-redux";
import Sidebar from "../Components/sidebar";
import styles from "../Styles/Dashboard.module.css";
import {
  FaPlay,
  FaEllipsisH,
  FaPaperPlane
} from "react-icons/fa";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = ({ collapsed, data, profile }) => {
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 12000,
      },
    },
  };

  return (
    <div className={styles.dashboardContainer}>
      <Sidebar />
      <div className={`${styles.content} ${collapsed ? styles.collapsed : ""}`}>
        <h1>Admin Dashboard</h1>
        <hr style={{ width: "100%" }} />
        <br />
        <br />
        <br />
        <br />
        <div className={styles.firstRow}>
          {/* First Div with all the smaller cards */}
          <div className={styles.firstDiv}>
            <div
              className={`${styles.dashboardCard} ${styles.activeUsers}`}
            ></div>
            <div className={`${styles.dashboardCard} ${styles.chatRequest}`}>
              <div className={styles.avatars}></div>
            </div>
            <div className={`${styles.dashboardCard} ${styles.userList}`}></div>
            <div
              className={`${styles.dashboardCard} ${styles.leadsEnquiry}`}
            ></div>
          </div>

          {/* Bar Chart on the Right */}
          <div className={`${styles.dashboardCard} ${styles.monthlyMembers}`}>
            <h3>Monthly Members</h3>

            <div className={styles.chart}>
              <Bar data={data} options={options} />
            </div>
          </div>
        </div>
        <br />
        <br />
        <h2 style={{ textAlign: "left", fontSize: "25px" }}>Active matches</h2>
        <p style={{ textAlign: "left", fontSize: "15px" }}>Ongoing match</p>
        {profile.map((profile,index) => {
          return (
            <div style={styles1.row} key={index}>
              <div style={styles1.cell}>{profile.id}</div>
              <div style={styles1.cell}>
                <img style={styles1.personImage} src={profile.image1}></img>
                <div>
                  <div>{profile.name}</div>
                  <div>{profile.email}</div>
                </div>
              </div>
              <div style={styles1.cell}>{profile.phone}</div>
              <div style={styles1.cell}>{profile.date}</div>
              <div style={styles1.cell}>
                <span style={{ ...styles1.status(profile.status) }}>{profile.status}</span>
              </div>
              <FaPaperPlane color="red" style={{marginRight:'30px',marginLeft:'20px'}} />
              <div style={styles1.cell}>
                <img style={styles1.personImage} src={profile.image2}></img>
                <div>
                  <div>{profile.matchName}</div>
                  <div>{profile.matchEmail}</div>
                </div>
              </div>
              <div style={styles1.cell}>{profile.matchPhone}</div>
              <div style={styles1.cell}>{profile.matchDate}</div>
              <div style={styles1.cell}>
                <span style={{ ...styles1.status(profile.matchStatus) }}>{profile.matchStatus}</span>
              </div>
              <div style={styles1.cell}>{profile.days}</div>
              <div style={styles1.cell1}><FaEllipsisH color="rgba(0,0,0,.5)"/></div>
            </div>
          );
        })}
      <br />
      <br />
      <br />
      <br />
      </div>
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  collapsed: state.sidebar.collapsed,
  data: state.dashboard.data,
  profile: state.dashboard.profile,
});

const styles1 = {
  row: {
    display: "flex",
    padding: "10px",
    borderBottom: "1px solid #ccc",
    alignItems: "center",
  },
  cell: {
    flex: 1,
    padding: "5px",
    display: "flex",
    alignItems: "center",
  },
  personImage: {
    marginRight: "10px",
    width:"40px",
    height:"40px",
    borderRadius:"5px",
    objectFit:"cover"
  },
  cell1:{
    height:'30px',
    width:'30px',
    
    borderRadius:'60px',
    borderColor:'rgba(0,0,0,.4)',
    border:'solid 1px',
    
  },
  status: (status) => ({
    padding: "5px 10px",
    borderRadius: "20px",
    color: "#fff",
    marginLeft:"0",
    backgroundColor: userStatusColor(status), 
  }),
};

function userStatusColor(status) {
  switch (status) {
    case "Single":
      return "#36C3FF";
    case "Married":
      return "#5A72FF";
    case "Gold":
      return "#FFC107";
    default:
      return "#ccc";
  }
}

export default connect(mapStateToProps)(Dashboard);
