import { SET_DATA, SET_PROFILE } from "../actions/dashboardActions";

const initialState = {
     data : {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "data-1",
            data: [
              3000, 4000, 6000, 8000, 9000, 10000, 11000, 8000, 7000, 5000, 4000,
              3000,
            ],
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
          },
        ],
      },
    
       profile : [
        {
          id: 1,
          image1:"person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
          image2:"person2.png",
          matchName: "Olivia mia",
          matchEmail: "ashleyipsum@gmail.com",
          matchPhone: "01 321-998-91",
          matchDate: "22, Feb 2024",
          matchStatus: "Single",
          days: "6 days",
        },
        {
          id: 1,
          image1:"person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
          image2:"person2.png",
          matchName: "Olivia mia",
          matchEmail: "ashleyipsum@gmail.com",
          matchPhone: "01 321-998-91",
          matchDate: "22, Feb 2024",
          matchStatus: "Single",
          days: "6 days",
        },
        {
          id: 1,
          image1:"person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
          image2:"person2.png",
          matchName: "Olivia mia",
          matchEmail: "ashleyipsum@gmail.com",
          matchPhone: "01 321-998-91",
          matchDate: "22, Feb 2024",
          matchStatus: "Single",
          days: "6 days",
        },
        {
          id: 1,
          image1:"person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
          image2:"person2.png",
          matchName: "Olivia mia",
          matchEmail: "ashleyipsum@gmail.com",
          matchPhone: "01 321-998-91",
          matchDate: "22, Feb 2024",
          matchStatus: "Single",
          days: "6 days",
        },
        {
          id: 1,
          image1:"person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
          image2:"person2.png",
          matchName: "Olivia mia",
          matchEmail: "ashleyipsum@gmail.com",
          matchPhone: "01 321-998-91",
          matchDate: "22, Feb 2024",
          matchStatus: "Married",
          days: "6 days",
        },
      ]
    
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
