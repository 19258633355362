import React from "react";
import Sidebar from "../Components/sidebar";
function Report(){

    return(
        <>
        <Sidebar/>
        <h1>Report</h1>
        </>
    )
}
export default Report;