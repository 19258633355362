import { SET_MALE,SET_FEMALE,setMale,setFemale } from "../actions/matchingActions";

const initialState = {
    male: [
        {
          id: 1,
          image1: "person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 2,
          image1: "person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 3,
          image1: "person1.png",
          name: "Ashley emyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 4,
          image1: "person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Married",
        },
        {
          id: 5,
          image1: "person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
      ],
    
       female : [
        {
          id: 1,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 2,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 3,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum3@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 4,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Married",
        },
        {
          id: 5,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
      ]
    
};

const matchingReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_MALE:
        return {
          ...state,
          male: action.payload,
        };
      case SET_FEMALE:
        return {
          ...state,
          female: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default matchingReducer;