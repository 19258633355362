import React, { useState } from "react";
import { connect } from "react-redux";
import Sidebar from "../Components/sidebar";
import styles from "../Styles/matching.module.css";
import { FaPlay, FaEllipsisH,FaRegPaperPlane } from "react-icons/fa";
function Matching({ collapsed, profile, male, female }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredActiveUsers, setFilteredActiveUsers] = useState([]);
  const [filteredExpiredUsers, setFilteredExpiredUsers] = useState([]);

  return (
    <>
      <Sidebar />
      <div className={`${styles.content} ${collapsed ? styles.collapsed : ""}`}>
        <br />
        <br />
        <div className="input-group mb-6">
          <input
            type="text"
            className="form-control"
            placeholder="Search Here"
            aria-label="Search Here"
            aria-describedby="basic-addon1"
            style={{ width: "80%", padding: "15px", background: "#E3E3E3" }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <br />
        <br />
        <h2 style={{ textAlign: "left", fontSize: "25px" }}>Active matches</h2>
        <p style={{ textAlign: "left", fontSize: "15px" }}>Ongoing match</p>
        {profile.map((profile,index) => {
          return (
            <div style={styles1.row} key={index}>
              <div style={styles1.cell}>{profile.id}</div>
              <div style={styles1.cell}>
                <img style={styles1.personImage} src={profile.image1}></img>
                <div>
                  <div>{profile.name}</div>
                  <div>{profile.email}</div>
                </div>
              </div>
              <div style={styles1.cell}>{profile.phone}</div>
              <div style={styles1.cell}>{profile.date}</div>
              <div style={styles1.cell}>
                <span style={{ ...styles1.status(profile.status) }}>
                  {profile.status}
                </span>
              </div>
              <FaRegPaperPlane
                color="red"
                style={{ marginRight: "30px", marginLeft: "20px" }}
              />
              <div style={styles1.cell}>
                <img style={styles1.personImage} src={profile.image2}></img>
                <div>
                  <div>{profile.matchName}</div>
                  <div>{profile.matchEmail}</div>
                </div>
              </div>
              <div style={styles1.cell}>{profile.matchPhone}</div>
              <div style={styles1.cell}>{profile.matchDate}</div>
              <div style={styles1.cell}>
                <span style={{ ...styles1.status(profile.matchStatus) }}>
                  {profile.matchStatus}
                </span>
              </div>
              <div style={styles1.cell}>{profile.days}</div>
              <div style={styles1.cell1}>
                <FaEllipsisH color="rgba(0,0,0,.5)" />
              </div>
            </div>
          );
        })}
        <br />
        <br />
        <div style={styles1.matchContainer}>
          <div className={{...styles1.activeMembersContainer,border:"solid 1px rgba(0,0,0,.2)"}}>
            <h2 style={{ textAlign: "left", fontSize: "25px" }}>Male</h2>
            <p style={{ textAlign: "left", fontSize: "15px" }}>
              Unmatched Profile will be sorted first
            </p>
            <table style={styles1.table}>
              <thead>
                <tr>
                  <th style={styles1.th}>NO</th>
                  <th style={styles1.th}>Profile</th>
                  <th style={styles1.th}>Name & Mail</th>
                  <th style={styles1.th}>Join Date</th>
                  <th style={styles1.th}>Status</th>
                  <th style={styles1.th}></th>
                </tr>
              </thead>
              <tbody>
                {male.map((user, index) => (
                  <tr key={index} style={styles1.tr}>
                    <td style={styles1.td}>{index + 1}</td>
                    <td style={styles1.td}>
                      <img
                        style={styles1.images}
                        src={user.image1}
                        alt="Profile"
                      />
                    </td>
                    <td style={styles1.td}>
                      <div style={styles1.nameEmailContainer}>
                        <span style={styles1.name}>{user.name}</span>
                        <span style={styles1.email}>{user.email}</span>
                      </div>
                    </td>

                    <td style={styles1.td}>{user.date}</td>
                    <td>
                      <span style={{ ...styles1.status(user.status) }}>
                        {user.status}
                      </span>
                    </td>
                    <td style={styles1.td}>
                      <FaEllipsisH color="rgba(0,0,0,.3)" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <div className={styles1.activeMembersContainer}>
            <h2 style={{ textAlign: "left", fontSize: "25px" }}>Female</h2>
            <p style={{ textAlign: "left", fontSize: "15px" }}>
              Unmatched Profile will be sorted first
            </p>
            <table style={styles1.table}>
              <thead>
                <tr>
                  <th style={styles1.th}>NO</th>
                  <th style={styles1.th}>Profile</th>
                  <th style={styles1.th}>Name & Mail</th>
                  <th style={styles1.th}>Join Date</th>
                  <th style={styles1.th}>Status</th>
                  <th style={styles1.th}></th>
                </tr>
              </thead>
              <tbody>
                {female.map((user, index) => (
                  <tr key={index} style={styles1.tr}>
                    <td style={styles1.td}>{index + 1}</td>
                    <td style={styles1.td}>
                      <img
                        style={styles1.images}
                        src={user.image1}
                        alt="Profile"
                      />
                    </td>
                    <td style={styles1.td}>
                      <div style={styles1.nameEmailContainer}>
                        <span style={styles1.name}>{user.name}</span>
                        <span style={styles1.email}>{user.email}</span>
                      </div>
                    </td>

                    <td style={styles1.td}>{user.date}</td>
                    <td>
                      <span style={{ ...styles1.status(user.status) }}>
                        {user.status}
                      </span>
                    </td>
                    <td style={styles1.td}>
                      <FaEllipsisH color="rgba(0,0,0,.3)" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  collapsed: state.sidebar.collapsed,
  profile: state.dashboard.profile,
  male: state.user.activeUsers,
  female: state.user.expiredUsers,
});
const styles1 = {
  matchContainer: {
    display: "flex",
    textAlign:"center",
    justifyContent:"center"
  },
 
  row: {
    display: "flex",
    padding: "10px",
    borderBottom: "1px solid #ccc",
    alignItems: "center",
  },
  cell: {
    flex: 1,
    padding: "5px",
    display: "flex",
    alignItems: "center",
  },
  personImage: {
    marginRight: "10px",
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    objectFit: "cover",
  },
  cell1: {
    height: "30px",
    width: "30px",

    borderRadius: "60px",
    borderColor: "rgba(0,0,0,.4)",
    border: "solid 1px",
  },
  table: {
    width: "60%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  th: {
    backgroundColor: "#F5F5F5",
    textAlign: "left",
    padding: "12px",
    fontSize: "14px",
    color: "#999",
    borderBottom: "2px solid #E5E5E5",
  },
  tr: {
    borderBottom: "1px solid #E5E5E5",
  },
  td: {
    padding: "12px",
    fontSize: "14px",
    color: "#333",
    verticalAlign: "left",
    textAlign: "left",
  },
  images: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    objectFit: "cover",
  },
  email: {
    color: "#999",
    fontSize: "12px",
    marginLeft: "0",
  },
  nameEmailContainer: {
    display: "flex",
    flexDirection: "column",
    width: "15rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  name: {
    fontSize: "14px",
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  email: {
    color: "#999",
    fontSize: "12px",
    marginLeft: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  activeMembersContainer:{
    padding:'10px',
    border:'solid 1px rgba(0,0,0,.9)',
    borderRadius:'5px'
  }
  ,
  status: (status) => ({
    padding: "5px 10px",
    borderRadius: "20px",
    color: "#fff",
    marginLeft: "0",
    backgroundColor: userStatusColor(status),
  }),
};

function userStatusColor(status) {
  switch (status) {
    case "Single":
      return "#36C3FF";
    case "Married":
      return "#5A72FF";
    case "Gold":
      return "#FFC107";
    default:
      return "#ccc";
  }
}

export default connect(mapStateToProps)(Matching);
