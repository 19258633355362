import { SET_ACTIVE_USERS, SET_EXPIRED_USERS, setActiveUsers,setExpiredUsers } from "../actions/userActions";

const initialState = {
    activeUsers : [
        {
          id: 1,
          image1: "person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 2,
          image1: "person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 3,
          image1: "person1.png",
          name: "Ashley emyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 4,
          image1: "person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Married",
        },
        {
          id: 5,
          image1: "person1.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
      ],
    
       expiredUsers : [
        {
          id: 1,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 2,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 3,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum3@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
        {
          id: 4,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Married",
        },
        {
          id: 5,
          image1: "person2.png",
          name: "Ashley emyy",
          email: "ashleyipsum@gmail.com",
          phone: "01 321-998-91",
          date: "22, Feb 2024",
          status: "Single",
        },
      ]
    
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_USERS:
      return {
        ...state,
        activeUsers: action.payload,
      };
    case SET_EXPIRED_USERS:
      return {
        ...state,
        expiredusers: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
