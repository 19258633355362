import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Sidebar from "../Components/sidebar";
import styles from "../Styles/User.module.css";
import { FaEllipsisH } from "react-icons/fa";

function User({ collapsed,activeUsers,expiredUsers }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredActiveUsers, setFilteredActiveUsers] = useState([]);
  const [filteredExpiredUsers, setFilteredExpiredUsers] = useState([]);

  useEffect(() => {
    const filteredActive = activeUsers.filter(
      (user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const filteredExpired = expiredUsers.filter(
      (user) =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredActiveUsers(filteredActive);
    setFilteredExpiredUsers(filteredExpired);
  }, [searchQuery]);

  return (
    <>
      <Sidebar />
      <div className={`${styles.content} ${collapsed ? styles.collapsed : ""}`}>
        <br />
        <br />
        <div className="input-group mb-6">
          <input
            type="text"
            className="form-control"
            placeholder="Search Here"
            aria-label="Search Here"
            aria-describedby="basic-addon1"
            style={{ width: "80%", padding: "15px", background: "#E3E3E3" }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <br />
        <br />
        <div className={styles.activeMembersContainer}>
          <h2 style={{ textAlign: "left", fontSize: "25px" }}>
            Active Members
          </h2>
          <p style={{ textAlign: "left", fontSize: "15px" }}>
            Currently availing service
          </p>
          <table style={styles1.table}>
            <thead>
              <tr>
                <th style={styles1.th}>NO</th>
                <th style={styles1.th}>Profile</th>
                <th style={styles1.th}>Name & Mail</th>
                <th style={styles1.th}>Join Date</th>
                <th style={styles1.th}>Status</th>
                <th style={styles1.th}></th>
              </tr>
            </thead>
            <tbody>
              {filteredActiveUsers.map((user, index) => (
                <tr key={index} style={styles1.tr}>
                  <td style={styles1.td}>{index + 1}</td>
                  <td style={styles1.td}>
                    <img
                      style={styles1.images}
                      src={user.image1}
                      alt="Profile"
                    />
                  </td>
                  <td style={styles1.td}>
                      <div style={styles1.nameEmailContainer}>
                        <span style={styles1.name}>{user.name}</span>
                        <span style={styles1.email}>{user.email}</span>
                      </div>
                    </td>
                  <td style={styles1.td}>{user.date}</td>
                  <td>
                    <span style={{ ...styles1.status(user.status) }}>
                      {user.status}
                    </span>
                  </td>
                  <td style={styles1.td}>
                    <FaEllipsisH color="rgba(0,0,0,.3)" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <div className={styles.activeMembersContainer}>
          <h2 style={{ textAlign: "left", fontSize: "25px" }}>
            Expired Members
          </h2>
          <p style={{ textAlign: "left", fontSize: "15px" }}>
            No longer availing service
          </p>
          <table style={styles1.table}>
            <thead>
              <tr>
                <th style={styles1.th}>NO</th>
                <th style={styles1.th}>Profile</th>
                <th style={styles1.th}>Name & Mail</th>
                <th style={styles1.th}>Join Date</th>
                <th style={styles1.th}>Status</th>
                <th style={styles1.th}></th>
              </tr>
            </thead>
            <tbody>
              {filteredExpiredUsers.map((user, index) => (
                <tr key={index} style={styles1.tr}>
                  <td style={styles1.td}>{index + 1}</td>
                  <td style={styles1.td}>
                    <img
                      style={styles1.images}
                      src={user.image1}
                      alt="Profile"
                    />
                  </td>
                  <td style={styles1.td}>
                      <div style={styles1.nameEmailContainer}>
                        <span style={styles1.name}>{user.name}</span>
                        <span style={styles1.email}>{user.email}</span>
                      </div>
                    </td>
                  <td style={styles1.td}>{user.date}</td>
                  <td>
                    <span style={{ ...styles1.status(user.status) }}>
                      {user.status}
                    </span>
                  </td>
                  <td style={styles1.td}>
                    <FaEllipsisH color="rgba(0,0,0,.3)" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  collapsed: state.sidebar.collapsed,
  activeUsers: state.user.activeUsers,
  expiredUsers: state.user.expiredUsers,
});



const styles1 = {
  table: {
    width: "60%",
    borderCollapse: "collapse",
    marginTop: "20px",
  },
  th: {
    backgroundColor: "#F5F5F5",
    textAlign: "left",
    padding: "12px",
    fontSize: "14px",
    color: "#999",
    borderBottom: "2px solid #E5E5E5",
  },
  tr: {
    borderBottom: "1px solid #E5E5E5",
  },
  nameEmailContainer: {
    display: "flex",
    flexDirection: "column",
    width: "15rem",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  name: {
    fontSize: "14px",
    color: "#333",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  email: {
    color: "#999",
    fontSize: "12px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  td: {
    padding: "12px",
    fontSize: "14px",
    color: "#333",
    verticalAlign: "left",
    textAlign:'left',
  },
  images: {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    objectFit: "cover",
  },
  email: {
    color: "#999",
    fontSize: "12px",
    marginLeft:'0'
  },
  status: (status) => ({
    padding: "5px 10px",
    borderRadius: "20px",
    color: "#fff",
    marginLeft:"0",
    backgroundColor: userStatusColor(status), 
  }),
};

function userStatusColor(status) {
  switch (status) {
    case "Single":
      return "#36C3FF";
    case "Married":
      return "#5A72FF";
    case "Gold":
      return "#FFC107";
    default:
      return "#ccc";
  }
}

export default connect(mapStateToProps)(User);