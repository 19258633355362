export const SET_MALE = "SET_MALE";
export const SET_FEMALE = "SET_FEMALE";

export const setMale = (male) => ({
  type: SET_MALE,
  payload: male,
});

export const setFemale = (female) => ({
  type: SET_FEMALE,
  payload: female,
});