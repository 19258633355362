export const SET_ACTIVE_USERS = "SET_ACTIVE_USERS";
export const SET_EXPIRED_USERS = "SET_EXPIRED_USERS";

export const setActiveUsers = (activeUsers) => ({
  type: SET_ACTIVE_USERS,
  payload: activeUsers,
});

export const setExpiredUsers = (expiredUsers) => ({
  type: SET_EXPIRED_USERS,
  payload: expiredUsers,
});